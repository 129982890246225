@import "./variables.scss";
@import "../mixins";

html {
  font-size: 10px;
}

body {
  background: $light-gray;
  font-family: $Poppins;
  font-size: 1.6rem;
  margin: 0;
  overflow-x: hidden;
  position: relative;
  max-width: 100%;
}

.content-container {
  top: 6rem;
  position: relative;
}

.container {
  padding-block: 4rem;
}
.wrapper {
  padding-inline: 8rem;
}

h1 {
  font-size: 5rem;
  font-weight: 600;
  margin-block: 0;
}

h2 {
  font-size: 3rem;
  font-weight: 600;
  margin-block: 0;
}

h3 {
  font-size: 2.5rem;
  font-weight: 600;
  margin-block: 0;
}
