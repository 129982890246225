.footer {
  border-top: 2px solid #e4e4e4;
  max-height: 20rem;
  padding-block: 2rem;
  text-align: center;

  &-logo {
    max-height: 20rem;
    max-width: 20rem;
  }
}
