.home-cta {
  padding-inline: $inline-padding;

  &-message,
  &-phone {
    color: #fff;
    font-size: 2.5rem;
    font-weight: 600;
    text-decoration: none;
    text-transform: uppercase;
  }

  @include breakpoint(pc) {
    padding-inline: 10rem;
  }

  @include breakpoint(device) {
    padding-inline: 4rem;
  }
}
