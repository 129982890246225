@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

// Fonts
$Poppins: "Poppins", sans-serif;

// Colors
$primary: rgb(0, 128, 55);
$gray: rgb(106, 106, 106);
$white: rgb(255, 255, 255);

// Light Colors
$light-primary: rgba($primary, 0.03);
$light-gray: rgba(248, 248, 248, 0.3);
$light-white: rgba($white, 0.3);

// Padding
$inline-padding: 23rem;

// Breakpoints
$galaxy: 360px;
$mobile: 480px;
$device: 820px;
$laptop: 1024px;
$desktop: 1200px;
