.before-after-slider {
  max-width: 50%;

  & img {
    max-height: 300px;
  }

  @include breakpoint(device) {
    max-width: 80%;
  }
}

.bas {
  &__title-container {
    margin-bottom: 3.2rem;
    padding-inline: $inline-padding;

    & hr {
      background-color: $primary;
      height: 0.2rem;
      margin-inline: auto;
      max-width: 20%;
      width: 14.5rem;
    }

    @include breakpoint(device) {
      padding-inline: 0;
    }
  }
  &__container {
    text-align: center;
  }

  &__title {
    color: #008037;
    font-weight: 600;
  }
}

.before-after-sliders {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
  place-content: center;
  place-items: center;

  @include breakpoint(device) {
    grid-template-columns: 1fr;
    row-gap: 2rem;
  }
}
