.cta-banner {
  background-color: transparent;
  background-image: radial-gradient(
    circle,
    rgba(30, 35, 74, 1) 0%,
    rgba(34, 49, 99, 1) 0%,
    rgba(38, 63, 123, 1) 100%,
    rgba(45, 90, 171, 1) 100%
  );
  filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.5));
  padding-block: 2rem;
  text-align: center;
  width: 100%;
}
