.chakra {
  &-accordion {
    display: grid;
    row-gap: 1rem;

    &__items {
      @include breakpoint(device) {
        padding-inline: 1rem;
      }
    }

    &__button {
      align-items: center;
      background-color: transparent;
      background-image: radial-gradient(
        circle,
        rgba(30, 35, 74, 1) 0%,
        rgba(34, 49, 99, 1) 0%,
        rgba(38, 63, 123, 1) 100%,
        rgba(45, 90, 171, 1) 100%
      );
      border: none;
      color: white;
      display: flex;
      filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.5));
      justify-content: space-between;
      max-height: 10rem;
      padding: 1rem;
      width: 100%;
    }
  }

  &-tabs {
    &__tablist {
      border-bottom: 0.2rem solid $gray;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      -webkit-box-pack: start;
    }

    &__tab {
      align-items: center;
      background-color: transparent;
      border: none;
      border-bottom-style: solid;
      border-bottom-width: 0.2rem;
      color: $gray;
      cursor: pointer;
      display: flex;
      font-size: 1.4rem;
      font-weight: 600;
      justify-content: center;
      margin-bottom: -0.2rem;
      outline: transparent solid 0.2rem;
      outline-offset: 0.2rem;
      padding-block: 1rem;
      padding-inline: 2rem;
      text-align: center;
      text-transform: uppercase;
      -webkit-box-align: center;
      -webkit-box-pack: center;

      &:hover {
        border-bottom: 0.2rem solid $primary;
        color: $primary;
      }

      &[aria-selected="true"] {
        color: $primary;
        border-bottom: 0.2rem solid $primary;
      }
    }

    &__tab-panel {
      padding-block: 4rem;
      padding-inline: 8rem;
    }
  }

  &-icon {
    width: 3rem;
  }
}

.services {
  padding-inline: $inline-padding;

  &__title-container {
    margin-bottom: 3rem;
    text-align: center;

    & hr {
      background-color: $primary;
      height: 0.2rem;
      margin-bottom: 1.5rem;
      margin-inline: auto;
      max-width: 20%;
      width: 14.5rem;
    }
  }

  &__title {
    color: $primary;
    font-weight: 600;
    text-align: center;
  }

  &__panel {
    &-content {
      column-gap: 10rem;
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;

      @include breakpoint(laptop) {
        margin-top: 2rem;
        flex-direction: column;
        align-items: center;
      }
    }

    &-image-container {
      img {
        width: 45rem;
        height: 35rem;

        @media screen and (max-width: 1500px) {
          width: 35rem;
        }

        @include breakpoint(galaxy) {
          width: 33rem;
        }
      }
    }

    &-description-container {
      max-width: 60%;

      @include breakpoint(laptop) {
        margin-top: 2rem;
        max-width: 100%;
      }
    }

    &-description {
      line-height: 2.8rem;
      margin-bottom: 2rem;

      @include breakpoint(device) {
        padding-inline: 2rem;
        text-align: center;

        & li {
          text-align: left;
        }
      }
    }
  }

  @include breakpoint(pc) {
    padding-inline: 10rem;
  }

  @include breakpoint(device) {
    padding-inline: 1rem;
  }
}
