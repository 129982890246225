.testimonials {
  background-image: radial-gradient(
    circle,
    rgba(30, 35, 74, 1) 0%,
    rgba(34, 49, 99, 1) 0%,
    rgba(38, 63, 123, 1) 100%,
    rgba(45, 90, 171, 1) 100%
  );
  color: #ffffff;
  display: flex;
  padding-inline: $inline-padding;

  button {
    background: none;
    border: none;
    font-size: 3rem;
    color: #ffffff;

    &:hover {
      cursor: pointer;
    }
  }

  .quotes {
    font-size: 3rem;
  }

  .testimonial {
    padding-inline: 5rem;
    text-align: center;
    font-size: 2rem;

    @include breakpoint(device) {
      padding-inline: 2rem;
    }
  }

  @include breakpoint(pc) {
    padding-inline: 10rem;
  }

  @include breakpoint(device) {
    padding-inline: 0;
  }
}

.swiper-slide {
  @include breakpoint(device) {
    display: grid;
    place-content: center;
  }
}
