.hero {
  align-items: center;
  background-image: url(../../../images/lawn.jpg);
  background-position: center;
  background-size: cover;
  display: flex;
  height: 60rem;

  &__logo {
    margin-bottom: 1rem;
    max-width: 30rem;
    max-height: 30rem;
  }

  &__description {
    font-size: 2.5rem;
    font-weight: 800;
    margin: 0;
    max-width: 50rem;
    text-align: center;
  }

  &__content-container {
    width: 100vw;
  }

  &__content {
    display: grid;
    place-content: center;
    place-items: center;
    padding-block: 5rem;
    background-color: $light-gray;
  }
}
