@mixin breakpoint($point) {
  @if $point == galaxy {
    // 360px
    @media (max-width: $galaxy) {
      @content;
    }
  } @else if $point == mobile {
    // 480px
    @media (max-width: $mobile) {
      @content;
    }
  } @else if $point == tablet {
    // 481px - 819px
    @media (min-width: ($mobile + 1px)) and (max-width: ($device - 1px)) {
      @content;
    }
  } @else if $point == device {
    // 820px
    @media (max-width: $device) {
      @content;
    }
  } @else if $point == laptop {
    // 1024px
    @media (max-width: $laptop) {
      @content;
    }
  } @else if $point == pc {
    // 1199px
    @media (max-width: ($desktop - 1px)) {
      @content;
    }
  } @else if $point == desktop {
    // 1200px
    @media (min-width: $desktop) {
      @content;
    }
  }
}
