.wwdt {
  background-color: white;
  padding-inline: $inline-padding;

  &__container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3rem;

    @include breakpoint(device) {
      align-items: center;
      flex-direction: column;
      row-gap: 2.5rem;
    }
  }

  &__content-container {
    max-width: 50%;

    & hr {
      background-color: $primary;
      height: 0.2rem;
      margin-bottom: 3rem;
      max-width: 20%;
      width: 14.5rem;
    }

    @include breakpoint(device) {
      max-width: 100%;
    }
  }

  &__title {
    color: $primary;
    font-weight: 600;
    text-align: center;
  }

  &__description {
    @include breakpoint(device) {
      padding-inline: 4rem;
      margin-bottom: 2rem;
    }
  }

  &__image-container {
    img {
      max-width: 35rem;
    }

    @include breakpoint(device) {
      max-width: 100%;
    }
  }

  @include breakpoint(pc) {
    padding-inline: 10rem;
  }

  @include breakpoint(device) {
    padding-inline: 0;
  }
}
